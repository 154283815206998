import React from 'react'
import styled from 'styled-components'

import { Button } from './UiComponents'
import FileUploader from './FileUploader'

export const UploadPlaygroundButton = props => {
    const { fileLink, onDelete, onUpload, readOnly } = props
    if (fileLink) {
        return <UploadContainer>
            {!readOnly && onDelete ? <CloseIcon onDelete={onDelete}/> : null}
            <FilledFileIcon />
            <DownloadButton as="a" href={fileLink} download>
                Download
            </DownloadButton>
        </UploadContainer>
    }
    return readOnly ? null : <UploadContainer>
        <GrayFileIcon />
        <WaterMark>Playground</WaterMark>
        <FileUploader name="playground" onUpload={({ url }) => onUpload(url)} onError={() => {}}/>
    </UploadContainer>
}

export const FilledFileIcon = () => {
    return <div>
        <svg width="99" height="136" viewBox="0 0 99 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6874 136H82.1174C93.1389 136 98.8047 129.937 98.8047 118.222V55.547H55.8833C49.5189 55.547 46.4141 52.1879 46.4141 45.4699V0H16.6874C5.82113 0 0 6.06268 0 17.6964V118.222C0 129.937 5.74356 136 16.6874 136ZM56.4267 49.4025H97.8734C97.6405 47.7639 96.3988 46.2073 94.7688 44.4868L56.8147 4.2603C55.2623 2.53981 53.7101 1.22888 52.2354 0.901192V44.9783C52.2354 48.0097 53.5548 49.4025 56.4267 49.4025Z" fill="#007AFF"/>
            <path d="M47.0123 112C57.4408 112 66 103.441 66 92.9877C66 82.5592 57.4162 74 46.9877 74C36.5592 74 28 82.5592 28 92.9877C28 103.441 36.5838 112 47.0123 112ZM45.1676 102.555C44.8233 102.555 44.5528 102.432 44.2576 102.063L38.0349 94.7832C37.8628 94.5618 37.7644 94.2913 37.7644 94.0453C37.7644 93.5042 38.2071 93.1599 38.6498 93.1599C38.9696 93.1599 39.2156 93.2582 39.4615 93.578L45.1184 100.268L55.1534 84.6253C55.3502 84.2809 55.6453 84.1088 55.9405 84.1088C56.3832 84.1088 56.8505 84.4531 56.8505 84.9696C56.8505 85.1909 56.7521 85.4369 56.6045 85.6582L46.0531 102.039C45.8317 102.408 45.512 102.555 45.1676 102.555Z" fill="white"/>
        </svg>
    </div>
}

export const GrayFileIcon = () => {
    return <div>
        <svg width="99" height="136" viewBox="0 0 99 136" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6874 136H82.1174C93.1389 136 98.8047 129.937 98.8047 118.222V55.547H55.8833C49.5189 55.547 46.4141 52.1879 46.4141 45.4699V0H16.6874C5.82113 0 0 6.06268 0 17.6964V118.222C0 129.937 5.74356 136 16.6874 136ZM56.4267 49.4025H97.8734C97.6405 47.7639 96.3988 46.2073 94.7688 44.4868L56.8147 4.2603C55.2623 2.53981 53.7101 1.22888 52.2354 0.901192V44.9783C52.2354 48.0097 53.5548 49.4025 56.4267 49.4025Z" fill="#E5E5E5"/>
        </svg>
    </div>
}

const CloseIcon = ({ onDelete }) => {
    return <CloseIconContainer onClick={onDelete}>
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" fill="#C4C4C4"/>
            <path d="M8.25977 19H11.0039L14.3242 13.8926H14.4902L17.7617 19H20.6719L16.0234 11.959L20.75 4.9082H17.918L14.6562 10.123H14.4902L11.2773 4.9082H8.29883L12.8691 11.9004L8.25977 19Z" fill="white"/>
        </svg>
    </CloseIconContainer>
}

const DownloadButton = styled(Button)`
    display: block;
    text-align: center;
    line-height: 1.75;
    margin: 1rem 0;
    padding: 1px 6px;
    height: 30px;
    font-weight: 600;
    font-size: 18px;
    min-width: 100px !important;
    text-decoration: none;
`

const CloseIconContainer = styled.div`
    position: absolute;
    top: -2px;
    right: -2px;
    cursor: pointer;
    transition: transform 0.1s linear;
    transform-origin: center;
    &:hover {
        transform: scale(1.1);
    }
`

const WaterMark = styled.div`
    position: absolute;
    bottom: 75px;
    right: 25px;
    color: #FFFFFF;
    font-size: 15px;
    cursor: default;
`

const UploadContainer = styled.div`
    position: relative;
    margin-bottom: 5rem;
    span {
        display: none;
    }
    label {
        margin: 1rem 0;
        > div {
            padding: 1px 6px;
            height: 30px;
            line-height: 1.75;
        }
    }
`