import React, { useState } from 'react';
import styled from 'styled-components';
import { StatusText, Button } from './UiComponents';
import { Modal } from './Modal'

export const AwaitingApproval = ({ text, onCancel }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return <Container>
        <StatusText>{text}</StatusText>
        <CancelButton onClick={() => setModalOpen(true)}>Cancel</CancelButton>

        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <h1>Are you sure you want to cancel asking this Question?</h1>
            <div>
                <CancelButton onClick={onCancel}>Yes, Cancel</CancelButton>
                <Button onClick={() => setModalOpen(false)}>No</Button>
            </div>
        </Modal>
    </Container>
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const CancelButton = styled(Button)`
    background-color: #FF2D55;
`