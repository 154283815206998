import React from 'react';
import { StepTitle } from './UiComponents';
import styled from 'styled-components';

export const Paywall = ({ creatorName }) => <Title>
    Your question has been answered. Please pay to view {creatorName}’s solution.
</Title>

const Title = styled(StepTitle)`
    text-align: center;
    margin-top: 25%;
`