import React from 'react';
import { StepTitle } from './UiComponents';
import styled from 'styled-components';
import { TextBox } from './UiComponents';

export const RejectedQuestion = ({ toAsker, reason }) => <>
    <Title>
        {toAsker
            ? 'We’re Sorry. Your question was rejected.'
            : 'This question has been rejected.'
        }
    </Title>
    <CenteredTextBox>{reason}</CenteredTextBox>
</>

const Title = styled(StepTitle)`
    text-align: center;
    margin-top: 15%;
`

const CenteredTextBox = styled(TextBox)`
    max-width: max(300px, 65vw);
    margin: auto;
`