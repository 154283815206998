import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './Modal';

export const MarkAsAnswered = ({ hasPlayground, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return <>
        <StyledLabel>
            <input
                type="checkbox"
                checked={false}
                onChange={() => hasPlayground ? onChange() : setModalOpen(true)}
            />
            Mark As Answered
        </StyledLabel>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <ModalHeader>Every answer must include a working Playground file.</ModalHeader>
        </Modal>
    </>
}

const StyledLabel = styled.label`
    display: block;
    text-align: right;
    cursor: pointer;
`

const ModalHeader = styled.h2`
    font-family: SF Pro Display, Suisse-Regular;
`