import React from 'react'
import zip from 'lodash/zip'
import merge from 'lodash/merge'
import styled from 'styled-components'
import { format } from 'date-fns'
import { getMessageHandler } from '../utils/immutableUtils';
import {
  StepTitle, Button,
} from '../components/UiComponents'
import { Messenger, Input } from '../components/Messenger'
import { UploadPlaygroundButton, GrayFileIcon } from '../components/UploadPlaygroundButton'
import { CancelledQuestion } from "./CancelledQuestion";
import { Paywall } from './Paywall';
import { RejectedQuestion } from './RejectedQuestion';
import { AwaitingApproval } from './AwaitingApproval';
import { Approval } from './Approval';
import { MarkAsAnswered } from './MarkAsAnswered';

export const perspectives = {
  ASKER: "ASKER",
  CREATOR: "CREATOR",
}

const states = {
  asked: "asked",
  accepted: "accepted",
  cancelled: "cancelled",
  rejected: "rejected",
  answered: "answered",
  answerViewed: "answerViewed",
}

export const QuestionRouter = props => {
  const {
    perspective,
    question,
    tip,
    update,
    updateField,
    asker,
    creator,
  } = props

  const { 
    creatorID,
    creatorPlayground,
    messageDates,
    messages: _messages,
    rejectionReason,
    senderIDs,
    subscriberID,
    state,
    subscriberPlayground,
    title,
  } = question;
  const messages = zip(_messages, senderIDs, messageDates)
    .map(([text, from, date]) => ({ text, from, date })) || [];
  const onMessage = getMessageHandler(question, update)
  const userID = perspective === perspectives.CREATOR ? creatorID : subscriberID;

  // Alternate pages depending on state/perspective
  if (state === states.cancelled) return <CancelledQuestion/>;
  if (state === states.rejected) return <RejectedQuestion reason={rejectionReason} toAsker={perspective === perspectives.ASKER}/>
  if (state === states.answered && perspective === perspectives.ASKER) return <Paywall creatorName={creator.givenName}/>

  // State Section component depending on state/perspective
  let StatusSection = null;
  if ((state === states.asked || state === states.accepted) && perspective === perspectives.ASKER) {
    const text = state === states.accepted ?
      `${creator.givenName} has reviewed your question and is working on it.`
      : `Your question is awaiting review and approval.`
    StatusSection = <AwaitingApproval text={text} onCancel={() => updateField('state', states.cancelled)}/>;
  } else if (state === states.asked && perspective == perspectives.CREATOR) {
    const onAccept = () => updateField('state', states.accepted);
    const onReject = rejectionReason => update({ ...question, state: states.rejected, rejectionReason });
    StatusSection = <Approval askerName={asker.givenName} price={tip.questionPrice} onAccept={onAccept} onReject={onReject}/>
  } else if (state === states.accepted && perspective === perspectives.CREATOR) {
    StatusSection = <MarkAsAnswered hasPlayground={!!creatorPlayground} onChange={() => updateField('state', states.answered)}/>
  } else if (state === states.answered && perspective === perspectives.CREATOR) {
    StatusSection = <StatusText>Awaiting payment...</StatusText>
  }

  // Display variables depending on state/perspective
  const shouldShowCreatorPlayground = (
    (perspective === perspectives.CREATOR && [states.accepted, states.answered, states.answerViewed].indexOf(state) > -1) ||
    (perspective === perspectives.ASKER && state === states.answerViewed)
  );
  const shouldShowInput = (state === states.answered || state === states.answerViewed) ||
    (state === states.accepted && perspective === perspectives.CREATOR)


  return <>
      <StepTitle>Question from {asker.givenName}</StepTitle>
      <FlexContainer>
        <QuestionSection>
          <QuestionTitle>
            <h2>{title}</h2>
            <Timestamp>
              {format(new Date(messageDates[0]), "h:mm aaaa 'on' MMM d, yyyy")}
            </Timestamp>
          </QuestionTitle>
          <Messenger
            messages={messages}
            currentUser={userID}
          />
          {shouldShowInput ? 
            <Input onMessage={onMessage} senderID={userID}>
              {state === states.accepted ? `Please review and respond within 48 hours.` : null}
            </Input>
          : null}
          {StatusSection}
        </QuestionSection>
        <PlaygroundSection>
          <UploadPlaygroundButton
            fileLink={subscriberPlayground}
            onDelete={() => updateField('subscriberPlayground', null)}
            onUpload={url => updateField('subscriberPlayground', url)}
            readOnly={perspective === perspectives.CREATOR}
          />
          {shouldShowCreatorPlayground ?
            <UploadPlaygroundButton
              fileLink={creatorPlayground}
              onDelete={() => updateField('creatorPlayground', null)}
              onUpload={url => updateField('creatorPlayground', url)}
              readOnly={perspective === perspectives.ASKER}
            />
          : null}
        </PlaygroundSection>
      </FlexContainer>
    </>
}

const QuestionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: SF Pro Display, Suisse-Regular;
  background-color: #007AFF;
  padding: 0.5rem 1rem;
  color: #FFFFFF;
  border-radius: 10px;
  > h2 {
    font-size: 32px;
    margin: 0;
  }
`

const Timestamp = styled.span`
  text-align: right;
`
  
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 1.5rem;
`
  
const QuestionSection = styled.div`
  flex: 2;
  min-width: min(500px, calc(100vw - 3.5rem));
`

const PlaygroundSection = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  button {
    margin: 1rem 0;
  }
`

const StatusText = styled.h3`
  font-family: SF Compact Text, SF Pro Display, Suisse-Regular;
  padding: 0 0.5rem 1rem;
`