import React, { useState } from 'react';
import styled from 'styled-components';
import { StatusText, Button, TextBox } from './UiComponents';
import { dollarPrices } from './PricePicker';
import { Modal } from './Modal';

export const Approval = ({ askerName, price, onAccept, onReject }) => {
    const [rejectionReason, setRejectionReason] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const handleReject = () => {
        onReject(rejectionReason);
        setRejectionReason('');
        setModalOpen(false);
    }
    return <Container>
        <StatusText>Can you answer { askerName }’s question for ${ dollarPrices[price] } ?</StatusText>
        <div>
            <CancelButton onClick={() => setModalOpen(true)}>No</CancelButton>
            <Button onClick={onAccept}>Yes</Button>
        </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <ModalBody>
                <h1>Are you sure you want to reject this Question?</h1>
                <TextArea as="textarea" value={rejectionReason} onChange={e => setRejectionReason(e.target.value)}/>
                <SendButton onClick={handleReject}>
                    <span>Yes, Reject</span>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.791 24.5483C15.7007 24.5483 16.3296 23.8408 16.7451 22.7627L24.2134 3.23291C24.3931 2.75 24.4941 2.32324 24.4941 1.95264C24.4941 1.08789 23.9551 0.548828 23.0903 0.548828C22.7197 0.548828 22.2817 0.649902 21.8101 0.82959L2.21289 8.32031C1.22461 8.70215 0.494629 9.33105 0.494629 10.252C0.494629 11.3525 1.30322 11.813 2.42627 12.1499L10.5234 14.5195L12.8818 22.5381C13.2188 23.7061 13.6792 24.5483 14.791 24.5483ZM11.2871 12.1724L4.9082 10.2183C4.76221 10.1733 4.70605 10.1284 4.70605 10.0498C4.70605 9.98242 4.75098 9.91504 4.88574 9.87012L16.79 5.28809C17.8008 4.89502 18.7891 4.42334 19.9458 3.89551C19.0923 4.5918 18.0479 5.44531 17.3179 6.16406L11.2871 12.1724ZM14.9932 20.3481C14.9146 20.3481 14.8696 20.2808 14.8247 20.1348L12.8706 13.7559L18.8789 7.7251C19.5752 7.01758 20.4736 5.92822 21.1475 5.07471C20.6196 6.24268 20.1479 7.23096 19.7549 8.25293L15.1729 20.1572C15.1167 20.2808 15.0718 20.3481 14.9932 20.3481Z" fill="white"/>
                    </svg>
                </SendButton>
            </ModalBody>
        </Modal>
    </Container>
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ModalBody = styled.div`
    position: relative;
    h1 {
        font-family: SF Pro Display, Suisse-Regular;
    }
`

const TextArea = styled(TextBox)`
    font-family: SF Pro Display, Suisse-Regular;
    border: none;
    margin: 2rem 0.25rem 1rem;
    padding-top: 2.5rem;
    width: -webkit-fill-available;
    min-height: 125px !important;
    resize: none;
`

const SendButton = styled(Button)`
    background-color: #FF2D55;
    position: absolute;
    bottom: 17px;
    right: 0px;
    span {
        vertical-align: top;
        line-height: 2.3;
        margin-left: 4px;
    }
    svg {
        margin: 7px;
    }
`

const CancelButton = styled(Button)`
    background-color: #FF2D55;
`