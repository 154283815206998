import React from 'react'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import { ColorRing } from 'react-loader-spinner';

import withLocation from '../components/withLocation';
import Layout from "../components/Layout";

import { QuestionRouter, perspectives } from "../components/QuestionRouter";

const getQuestionQuery = gql`
query getQuestion ($questionID: ID!) {
  getQuestion(id: $questionID) {
    acceptedDate
    creatorID
    answerDate
    creatorMessageDates
    creatorMessages
    id
    paymentDate
    rating
    rejectionReason
    state
    subscriberMessageDates
    subscriberID
    subscriberMessages
    tipID
    title
  }
}
`;

const getUserQuery = gql`
query getCreator ($userID: ID!) {
  getCreator(id: $userID) {
    description
    email
    familyName
    github
    givenName
    id
    image
    isFeatured
    location
    tipIDs
    twitter
    website
  }
}
`

const getTipPriceQuery = gql`
  query getTip ($tipID: ID!) {
    getTip(id: $tipID) {
      questionPrice
    }
  }
`

const updateQuestionQuery = gql`
mutation updateQuestion($question: UpdateQuestionInput!) {
  updateQuestion(input: $question) {
      acceptedDate
      creatorID
      answerDate
      creatorMessageDates
      creatorMessages
      id
      paymentDate
      rating
      rejectionReason
      state
      subscriberMessageDates
      subscriberID
      subscriberMessages
      tipID
      title
  }
}
`

const Question = props => {
  const { params: { questionID, creatorID } } = props;
  const { data: { getQuestion } = {}, loading } = useQuery(getQuestionQuery, { variables: { questionID }});
  const newLocal = useQuery(getUserQuery, { variables: { userID: getQuestion && getQuestion.subscriberID } });
  const { data: { getCreator: asker } = {}, loading: askerLoading, error: askerError } = newLocal;
  const { data: { getCreator: creator } = {}, loading: creatorLoading, error: creatorError } = useQuery(getUserQuery, { variables: { userID: getQuestion && getQuestion.creatorID }});
  const { data: { getTip } = {} } = useQuery(getTipPriceQuery, { variables: { tipID: getQuestion && getQuestion.tipID }});
  const [mutate, { data: { updateQuestion } = {}}] = useMutation(updateQuestionQuery);
  const question = updateQuestion || getQuestion;

  const update = updatedQuestion => mutate({ variables: { question: omit(updatedQuestion, ['__typename']) }});

  const updateField = (fieldName, value) => {
      const clone = merge({}, getQuestion);
      clone[fieldName] = value;
      update(clone);
  };

  // updateField('state', 'asked')

  return <Layout compact="true">
    <ColorRing
      type="TailSpin"
      color="#00BFFF"
      visible={loading || askerLoading || creatorLoading}
      size={300}
      style={{ textAlign: 'center' }}
    />
      {question && asker && creator && getTip ? <QuestionRouter
        perspective={creatorID ? perspectives.CREATOR : perspectives.ASKER}
        question={question}
        tip={getTip}
        update={update}
        updateField={updateField}
        asker={asker}
        creator={creator}
      /> : null}
    {/* <pre>{JSON.stringify(question, null, 2)}</pre>
    Asker - <pre>{JSON.stringify(asker, null, 2)}</pre>
    Creator - <pre>{JSON.stringify(creator, null, 2)}</pre>
    Tip - <pre>{JSON.stringify(getTip, null, 2)}</pre> */}
  </Layout>
}

export default withLocation(Question);